.main_frame{

}
.pd_frame_1{
    width: 100vw;
    display: flex;
    height: 50vh;
    min-height: none;
    position: relative;
    background-image: url("./carousel_images/1.jpg");
}
.pd_frame_1 p{
    padding-left: 30px;
    padding-top: 90px;
}
.pricing_text{
    font-family: Font_bold;
    color: #a3bb33;
    font-size: 7rem;  
}
.details_text{
    font-family: Font_bold;
    color: white;
    font-size: 7rem;  
}
.pd_frame_2{
    display: flex;
    flex-direction: column;
    background-image: url("./carousel_images/1.jpg");
    padding-top: 50px;
    padding-bottom: 50px;
}
.exercise_sliding_bar{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.type_exercise_container{
    width: 50%;
}
.type_exercise_container button{
    height: 3rem;
    
}
.sb_left_arrow_button{
    background-color: gray;
    width: 40px;
}
.sb_right_arrow_button{
    background-color: gray;
    width: 40px;
}

.session_selection_info_container{
    background-color: white;
    border:1px solid black;
    border-radius: 25px;
    width: 80%;
    height: 80%;
    margin: auto;
    padding:50px;
}
.session_selection_info_container_row1 button{
    font-size: 1rem;
}
.session_selection_info_container_row2{
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align:top;
}
.session_selection_info_container_row2 button{
    width: 30%;
    height:200px;
    vertical-align: top;
}
.session_selection_info_container_row2 button p{
font-size: 3rem;
}

.session_selection_info_container_row2 button h2{
    margin: auto;
    text-align: center;
    position: relative;
    display: inline-block;
    font-size: 3rem;
 }
.session_selection_info_container_row2 button h2::after{
    content:'';
    background:white;
     width:100%;
     height:3px;
     display:block;
     
 }
 .session_selection_info_container_row2 button h4{
    margin: auto;
    text-align: center;
    position: relative;
    display: inline-block;
 }
.session_selection_info_container_row2 button h4::after{
    content:'';
    background:white;
     width:100%;
     height:3px;
     display:block;
     
 }
/*Row 3*/
.session_selection_info_container_row3{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}
.trainers_tag{
    font-family: Font_medium;
    font-size: 1.3rem;
    margin-right: 1rem;
    padding: 0.4rem 1.5rem;
    border-radius: 1rem;
    transition: all ease 0.2s;
    color: white;
    background: rgb(162, 185, 51);
    background: linear-gradient(
      180deg,
      rgba(162, 185, 51, 1) 67%,
      rgba(83, 94, 25, 1) 98%
    );
    box-shadow: 2px 2px 4px grey;
}
.male_checkbox {
    font-family: Font_medium;
    font-size: 1.5rem;
    color: gray;
    margin-top: 5px;
}
.female_checkbox{
    font-family: Font_medium;
    font-size: 1.5rem;
    color: gray;
    margin-top: 5px;

}
.male_checkbox input{
width: 20px;
height: 20px;
}

.female_checkbox input{
    width: 20px;
    height: 20px;    
}

.search_button{
    margin-top:-20px;
    width:180px;
    height:50px;
    font-family: Font_medium;
    font-size: 1.3rem;
    margin-left:auto;
    margin-right: auto;
    cursor: pointer;
    padding: 0.4rem 1.5rem;
    outline: none;
    border-radius: 3rem;
    transition: all ease 0.2s;
    border: 2px transparent;
    color: white;
    background: rgb(162, 185, 51);
    background: linear-gradient(
      180deg,
      rgba(162, 185, 51, 1) 67%,
      rgba(83, 94, 25, 1) 98%
    );
    box-shadow: 2px 2px 4px grey;
}
/*Third Section Starts here*/
.pd_frame_3{
    background-image: url("./carousel_images/2.jpg");
}
.right_side_filter_button{
   text-align: right;
   margin-right: 85px;
   padding-top: 40px;
}
.right_side_filter_button button{
 background-color: #a3bb33;
 border-radius: 20px;
}
.search_results_exercise_table tr td{
    border:0;
}
.left_side_exercise_contents{
    width:35%;
    vertical-align:top;
}
.left_side_exercise_contents span{
}
.left_exercise_name{
    font-family: Font_bold;
    color: #a3bb33;
    font-size: 7rem;  
    padding:0;
    margin:3rem;
}
.left_exercise_information{
    font-family: Font_smedium;
    font-size: 1.5rem;
    color: white;
}
.right_side_trainer_session_cards{
}
.right_side_trainer_session_cards_column{
    cursor:auto;
    position: relative;

}
.search_results_exercise{
}
.trainer_session_card{
    width: 27%;
    height: 400px;
    float: left;
    border-radius: 10px;
    background-color: #a3bb33;
    margin: 10px;
    flex-direction:column;
    justify-content: center;
    color: white;
    font-family: Font_medium;
    margin-left: 40px;

}
.octagon_outer{
    transform: rotate(-22.5deg);
    width:20vh;
    height:20vh;
    overflow:hidden;
    margin-top:20px;
    margin-left: 20%;
}
.octagon_inner{
    transform: rotate(45deg);
    width:20vh;
    height:20vh;
 background-size: auto 20vh;
 overflow:hidden;
}
.octagon_inner_most{
width:20vh;
height:20vh;
overflow:hidden;
 background-size: auto 20vh;
 background-repeat: no-repeat;
}
.octagon_img1{
    margin-top:-8px;
    margin-left:-8px;
width:22vh;
height:22vh;
 background-repeat: no-repeat;
transform: rotate(-22.5deg);
}





.trainer_name_txt{
    font-size: 2rem;
    text-align: center;
}
.trainer_exercise_txt{
    text-align: center;
}
.trainer_experience_txt{
text-align: center;
}

.trainer_monthly_fee_txt{
    background-color: black;
    border-radius: 2rem;
    height: 20px;
    padding-top:6px;
    width: 70%;
    margin-left: 15%;
    text-align: center;

 }
.trainer_slot_time_heading_txt{
    font-size: 1.2rem;
    text-align: center;
}
.trainer_slot_time_txt{
    font-size:1.3rem;
    text-align: center;
}
.go_up_button_div{
    position: absolute;
right: 0; 
bottom: 0;
padding: 10px;
}
.go_up_button_button{
    background-color: white;
    width: 60px;
    height: 60px;
    padding: 0;
}
.go_up_button_button img{
    background-color: #a3bb33;
    border-radius: 40 px;
}
.exercise_button {
    font-family: Font_medium;
    font-size: 1.3rem;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 10rem;

    cursor: pointer;
    border: none;
    box-shadow: 0 0 0 2px #a3a3a3;
    background-color: transparent;
    color: #a3a3a3;
    padding: 0.4rem 1.5rem;
    outline: none;
    border-radius: 1rem;
    transition: all ease 0.2s;
    background-color: white;
  }
  .exercise_button:hover {
    box-shadow: 2px 2px 8px grey;
  }
  .exercise_button:active {
    transform: scale(98%);
  }
  .exercise_button:focus {
    border: 2px transparent;
    color: white;
    background: rgb(162, 185, 51);
    background: linear-gradient(
      180deg,
      rgba(162, 185, 51, 1) 67%,
      rgba(83, 94, 25, 1) 98%
    );
    box-shadow: 2px 2px 4px grey;
  }
  .exercise_button_selected{
    font-family: Font_medium;
    font-size: 1.3rem;
    margin-right: 1rem;
    cursor: pointer;
    padding: 0.4rem 1.5rem;
    outline: none;
    border-radius: 3rem;
    transition: all ease 0.2s;


    border: 2px transparent;
    color: white;
    background: rgb(162, 185, 51);
    background: linear-gradient(
      180deg,
      rgba(162, 185, 51, 1) 67%,
      rgba(83, 94, 25, 1) 98%
    );
    box-shadow: 2px 2px 4px grey;
  }
  .exercise_button_unselected{
    font-family: Font_medium;
    font-size: 1.3rem;
    margin-right: 1rem;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 0 2px #a3a3a3;
    background-color: transparent;
    color: #a3a3a3;
    padding: 0.4rem 1.5rem;
    outline: none;
    border-radius: 3rem;
    transition: all ease 0.2s;
  }
