.main {
  font-family: "Roboto Condensed", sans-serif;
}
.upper_txt {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;
  width: 90%;
  border: 2px solid #4a4a4a;
  color: #4a4a4a;
  padding: 0 2.2rem;
  border-radius: 4px;
  font-size: 1.2rem;
  filter: drop-shadow(2px 2px 4px rgb(167, 167, 167));
}
.tag {
  background-color: #13a89e;
  width: max-content;
  margin: 2rem auto 1rem;
  font-size: 1.2rem;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  color: white;
  box-shadow: 2px 2px 8px #13a89e59;
}
