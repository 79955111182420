.abt_hexa_top{
    float: left;
    border-right: 26px solid rgb(0, 0, 126);
border-top: 26px solid transparent;
border-bottom: 26px solid transparent;
}
.abt_hexa_mid{
    float: left;
    width: 156px;
height: 52px;
background-color: rgb(0, 0, 126);

}
.abt_hexa_mid p{
    font-size: 1.2rem;
    text-align: center;
    color: white;
}
    
.abt_hexa_bottom{
float: left;
border-left: 30px solid rgb(0, 0, 126);
border-top: 26px solid transparent;
border-bottom: 26px solid transparent;
}
.abt_hexa_container{
    width:100%;
    font-family: Font_medium;
    position: absolute;
    left:42%; 
}
.abt_container{
    height: 100%;
    width: 100%;
    display: inline-block;
    overflow: visible;
}
.abt_fading_line_left{
    position: absolute;
    border:none;
    color:white;
    height:2px;
    width:42.2%;
    top:26px;    
    float: left;
    vertical-align: middle;
    background-color:black;
    background: -webkit-gradient(radial, 0% 2%, 0, 50% 50%, 400, from(#fff), to(#000));
}
.abt_fading_line_container_left{
    width: 100%;
    margin:0 auto;
    position: relative;
}


.abt_fading_line_right{
    position: absolute;
    border:none;
    color:white;
    height:2px;
    width:46%;
    left:53.5rem;
    top:26px;    
    float: left;
    vertical-align: middle;
    background-color:black;
    background: -webkit-gradient(linear,left top,right top, from(#000), to(#fff));
}
.abt_fading_line_container_right{
    width: 100%;
    margin:0 auto;
    position: relative;
}
.abt_table{
    border-width: 0;
}
.abt_table tr td{
    width:100px;
    text-align: center;
}
.abt_table_heading td{
color:rgb(0, 0, 126);
font-size: 2rem;
font-weight: bold;
}
.abt_table_content td{
    font-size: 1rem;
}