* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.mobile_menu,
.nav_logo_mobile {
  display: none;
}
.main_ctr {
  width: 100vw;
  height: 100%;
  background-color: #191919;
}
.vw_1 {
  width: 100vw;
  display: flex;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  margin-bottom: 1rem;
}

.fixed_ctr {
  transform: rotate(-90deg);
  z-index: 99;
  position: fixed;
  width: max-content;
  display: flex;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 2.2rem;
  right: -8.7em;
}
.fixed_ctr button {
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  background-color: black;
  color: white;
  border-radius: 2rem 2rem 0 0;
  border: none;
  margin-right: 2rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.815);
}
.ctr1 {
  width: 70vw;
  min-height: 100vh;
  height: 100%;
  bottom: 0;
}
.ctr2 {
  position: relative;
  left: 0;
  width: 30vw;
  z-index: 3;
  background-color: #a3bb33;
  /* overflow-y: scroll; */
  height: 100vh;
}
.nav {
  position: absolute;
  z-index: 2;
  display: flex;
  width: 90%;
  padding: 2rem;
  padding-right: 0 !important;
}
.nav p {
  font-family: Font_thin;
  font-size: 1.1rem;
  display: flex;
  color: #a3bb33;
  align-items: center;
  cursor: pointer;
}
.nav p:hover {
  color: #deff4a;
}
.nav_logo {
  height: 5rem;
}
.icon {
  height: 1.5rem;
  margin-right: 0.4rem;
}
.navigations {
  position: absolute;
  display: flex;
  justify-content: space-around;
  width: 51%;
  right: 32%;
}
.carousal {
  position: relative;
  width: 100%;
}
.carousal img {
  height: 100vh;
}
.nav2 {
  display: flex;
  padding-top: 3rem;
  position: absolute;
  z-index: 4;
  display: flex;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  justify-content: space-around;
}
.join_btn {
  display: flex;
  border: 3px solid black;
  color: black;
  border-radius: 2rem;
  background-color: transparent;
  padding: 0.1rem 0.2rem;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
  padding-right: 0.4rem;
  font-weight: 500;
}
.join_btn img {
  filter: brightness(0);
  height: 1.2rem;
  border: 3px solid black;
  border-radius: 50%;
  padding: 0.3rem;
}
.user_ico {
  background-color: transparent;
  font-size: 1.8rem;
}

.login_btn:hover,
.join_btn:hover {
  background-color: black;
  color: #a3bb33;
}
.join_btn:hover img {
  filter: brightness(1);
  border: 3px solid #a3bb33;
}
.login_btn {
  padding-right: 0.4rem;
  display: flex;
  border: 3px solid black;
  color: black;
  border-radius: 2rem;
  background-color: transparent;
  padding: 0.1rem 0.2rem;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 500;
}
.trial_ctr {
  background-color: white;
  width: max-content;
  padding: 0.3rem 2rem;
  margin: 0 auto;
  margin-top: 7.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2rem;
  font-size: 2rem;
  font-weight: 600;
  box-shadow: 0 0 16px 12px rgba(83, 83, 83, 0.171);
}
.trial_ctr p {
  font-family: Font_medium;
}
.trial_inp {
  width: 100%;
  display: flex;
  border-bottom: 2px solid black;
  padding: 0.4rem;
  margin-bottom: 1.8rem;
  align-items: center;
}
.trial_inp input[type="text"] {
  margin-left: 0.4rem;
  border: none;
  outline: none;
  font-size: 1.1rem;
}
/* Changes here - New css class added for password input block*/
.trial_inp input[type="password"] {
  margin-left: 0.4rem;
  border: none;
  outline: none;
  font-size: 1.1rem;
}
/*Added login error label class */
.login_error_label{
  font-size: 1rem;
  color : red;
  background: white;
  padding: 0 0.8rem;  
}
.trial_login {
  padding: 0.4rem 2rem;
  background: rgb(159, 182, 50);
  background: linear-gradient(
    180deg,
    rgba(159, 182, 50, 1) 47%,
    rgba(85, 97, 26, 1) 85%
  );
  border: none;
  border-radius: 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: white;
  text-shadow: -2px 2px 4px rgba(255, 255, 255, 0.541);
}
.trial_login:hover {
  transform: translateY(4%);
}
.img_ctr_1 {
  position: relative;
}
.line_ctr {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}
/*  border: 1px solid black;
*/
.line_ctr p {
  position: absolute;
  top: -1.9rem;
  font-size: 1rem;
  background: white;
  padding: 0 0.8rem;
}
.trial_media_ctr {
  display: flex;
  width: 50%;
  justify-content: space-around;
  margin-bottom: 0.8rem;
}
.trial_media {
  cursor: pointer;
}
.trial_media:hover {
  transform: translateY(2%);
}
.img_txt {
  top: 16rem;
  left: 4rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 27rem;
  text-align: left;
  height: 100%;
  width: 50%;
  text-shadow: 2px 2px 16px rgba(0, 0, 0, 0.568);
  user-select: none;
}
.img_txt .img_txt_1 {
  font-family: Font_smedium;
  color: white;
  font-size: 6rem;
}
.img_txt .img_txt_2 {
  font-family: Font_bold;
  color: #a3bb33;
  font-size: 8rem;
}
.carousel_image1,
.carousel_image2,
.carousel_image3 {
  background-color: black;
  height: 100vh;
  color: white;
  background-size: contain;
  background-repeat: no-repeat;
}
.carousel_image1 {
  background-image: url("./carousel_images/1_new.jpg");
}
.carousel_image2 {
  background-image: url("./carousel_images/2_new.jpg");
}
.carousel_image3 {
  background-image: url("./carousel_images/3_new.jpg");
}
.carousel_image2_txt{
  position: absolute;
  right:40px;
  bottom:100px;
}
.carousel_image2_txt ul{
  list-style-type: none;
}
.carousel_image2_txt ul li{
  font-family: Font_smedium;
  color: #a3bb33;
  font-size: 3rem;
}
.carousel_image3_txt1{
font-family: Font_medium;
color: #a3bb33;
font-size: 3rem;
position:relative;
left:25px;
top:70px;
}
.carousel_image3_txt2{
  position: relative;
  left:40px;
  top:70px;
}
.carousel_image3_txt2{
  list-style-type: none;
}
.carousel_image3_txt2 li{
  font-family: Font_smedium;
  color: white;
  font-size: 2rem;
}
.carousel_image3_txt3{
  font-family: Font_smedium;
  color: #a3bb33;
  font-size: 2.5rem;
  position: relative;
  left:40px;
  top:80px;
  }
  
.join_btn_ctr1 {
  display: flex;
  border: 3px solid #a3bb33;
  color: #a3bb33;
  border-radius: 2rem;
  background-color: transparent;
  padding: 0.4rem 0.8rem;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  cursor: pointer;
  font-weight: 500;
}
.join_btn_ctr1 img {
  height: 1.2rem;
  border: 3px solid #a3bb33;
  border-radius: 50%;
  padding: 0.3rem;
  margin-right: 1rem;
}
.ctr1_line {
  padding-bottom: 0.8rem; 
  border-bottom: 0px solid blue;
  position: absolute;
  z-index: 9;
  bottom: 4rem;
  width: 25%;
  margin-left: 4rem;
  margin-bottom: -60px;
}

.best_txt {
  margin: 0 auto;
  width: max-content;
  text-align: left;
  margin-top: 1.5rem;
}
.best_fitbuddy {
  font-family: Font_medium;
  font-size: 3.5rem;
}
.best_session {
  color: white;
  font-family: Font_medium;
  font-size: 2.8rem;
}
.best_arrow {
  font-size: 11rem;
}
.best_lower_ctr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.carousal2 {
  width: 80%;
  margin: 0 auto;
  border-radius: 2rem;
  background-color: black;
  overflow: hidden;
  margin-bottom: 1rem;
}
.carousal2 video {
  height: 14rem;
  cursor: pointer;
}
.join_btn_ctr1:hover {
  transform: translateY(2px);
}

/*///////////////////////////////////////////////////////// vw2 */

.vw_2 {
  position: relative;
  height: max-content;
  min-height: 100vh;
  width: 100vw;
  background-color: black;
  margin-bottom: 1rem;
}
.line_nav {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vw_2_our,
.vw_2_session {
  color: white;
}
.vw_2_our {
  font-family: Font_bold;
}
.vw_2_session {
  font-family: Font_light;
  margin-left: 1rem;
}
.arr_right,
.arr_left {
  font-size: 6rem !important;
}
.arr_right:hover,
.arr_left:hover {
  font-size: 6.1rem !important;
}
.txt1 {
  font-size: 4rem;
  display: flex;
  margin-left: 4rem;
}
.line_nav button {
  margin-right: 4rem;
  font-family: Font_thin;
  color: #a3bb33;
  border: 3px solid #a3bb33;
  background-color: transparent;
  height: max-content;
  font-size: 2rem;
  padding: 0 0.5rem;
}
.card_ctr {
  display: flex;
  border: 2px solid red;
  height: 38rem;
}

/*///////////////////////////////////////////////////////// vw3 */
.phone_media_icons{
  display: none !important;
}
.vw_3 {
  height: 120vh;
  width: 100vw;
  background-color: #a3bb33;
  display: flex;
  margin-bottom: 1rem;
  min-height: max-content;
}
.vw_3_ctr_1 {
  display: flex;
  width: 40vw;
  height: 110vh;
  flex-direction: column;
  text-align: left;
  margin-left: 4rem;
  justify-content: space-around;
  margin-top: 20px;
}
.vw_3_txt {
  height: max-content;
  line-height: 0px;
}
.vw_3_why {
  font-size: 4rem;
  font-family: Font_medium;
}
.v3_fitbuddy {
  font-family: Font_bold;
  color: white;
  font-size: 6rem;
}
.vw_3_txt_box {
  display: flex;
  line-height: 22px;
}
.vw_3_txt_box img {
  margin: auto 0;
  width: 6rem;
  height: 7rem;
  margin-right: 2rem;
}
.vw_3_txt_box_line1 {
  font-family: Font_medium;
  font-size: 2rem;
}
.vw_3_txt_box_line2 {
  font-family: Font_smedium;
  font-size: 1.2rem;
}
.vw_3_ctr_2 {
  height: 100vh;
  display: flex;
}
.vw_3_ctr_2 .video_ctr {
  width: max-content;
  height: max-content;
  background-color: black;
}
.video_ctr video {
  height: 50vh !important;
  background-repeat: no-repeat;
}
.vw_3_pre_video,
.vw_3_post_video {
  color: white;
  background-color: #499e99;
  padding: 1rem;
  font-size: 2rem;
  font-family: Font_medium;
}
.vw_3_pre_video {
  border-radius: 1rem 1rem 0 0;
}
.vw_3_post_video {
  border-radius: 0 0 1rem 1rem;
}
.vw_3_video_frame {
  margin: auto 0;
}

/* //////////////////////////////////////vw4 */

.vw_4 {
  min-height: 100vh;
  height: max-content;
  width: 100vw;
  background-image: url("./required_images/gym.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 1rem;
}
.vw_4_overlay {
  background-color: black;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.7;
}
.mem_txt {
  position: absolute;
  top: -0.8rem;
  left: 0;
  right: 0;
  text-align: center;
  color: #a3bb33;
  font-size: 6rem;
  font-family: Font_I_thin;
}
/*.membership_card {
   width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
  right: 0;
  left: 0;
  position: absolute;
  top: 18rem;
  border: 2px solid red;
  color: white;
  height: max-content; 
}*/
.membership_card {
  margin: 0 2rem;
  width: 18rem;
  background-color: black;
  border-radius: 1rem;
  border-right: 6px solid #499e99;
  border-bottom: 6px solid #499e99;
  color: white;
  font-family: Font_light;
}
.plan_readbtn {
  color: #499e99;
  border: 1px solid #499e99;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  background-color: transparent;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.mem_duration {
  display: flex;
  align-items: center;
  text-align: center;
  width: max-content !important;
  margin: 0 auto;
  align-self: center;
  font-size: 1.2rem;
}
.membership_card img {
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  border-bottom: 3px solid #499e99;
}
.membership_card:hover {
  transform: translateY(-2%);
}
.ssn_time_by_you {
  background-color: #499e99;
  width: max-content;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  margin: 0 auto;
  font-size: 1.2rem;
}
.plan_name {
  font-family: Font_I_thin;
  font-size: 2rem;
  padding-bottom: 1rem;
  border-bottom: 3px solid #499e99;
}

/* ////////////////////////// vw5 */

.vw_5 {
  margin-top: 2rem;
  height: max-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vw_5_s1 {
  height: max-content;
  width: 100%;
  position: relative;
  background-color: black;
  overflow: hidden;
}
.review_txt {
  color: #a3bb34;
  font-size: 2.8rem;
  text-align: center;
  font-family: Font_medium;
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
}
.vw_5_s1_bakimg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  min-height: 100%;
  opacity: 0.2;
}
.vw_5_img_ctr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}
.stars {
  color: #a3bb34;
  margin: 2rem;
  font-size: 2rem;
}
.vw_5_review_txt {
  color: white;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  font-family: Font_I_regular;
  font-size: 1.1rem;
  padding-bottom: 1rem;
}
.vw_5_s1_profile {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  border: 6px solid #a3bb34;
}
.vw_5_s1_line {
  border-bottom: 6px solid #a3bb34;
  width: 8%;
  margin: 2rem auto;
}

.vw_5_s2 {
  min-height: max-content;
  height: 28%;
  background: rgb(163, 187, 51);
  background: linear-gradient(
    0deg,
    rgba(163, 187, 51, 1) 47%,
    rgba(99, 175, 15, 1) 85%
  );
  width: 100%;
  margin: 1rem 0;
}
.vw_5_s2 p {
  font-family: Font_italic;
  font-size: 3.1rem;
  margin-right: 2rem;
}
.vw_5_s2 span {
  font-family: Font_I_Xthin;
  font-size: 3.1rem;
}
.vw_5_s2_txt {
  display: flex;
  height: 3rem;
  align-items: center;
  margin: 2rem auto 1rem;
  width: max-content;
}
.vw_5_s2_line {
  border-bottom: 4px solid black;
  width: 10rem;
  margin: 0 auto;
}

.vw_5_s3 {
  height: 34%;
  background-color: black;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
}
.client_logos_ctr {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  height: max-content;
}
.client_logos {
  height: 8rem;
  margin: 0 2rem;
}
.vw_5_s3_join {
  color: #a3bb33;
  font-family: Font_I_thin;
  margin: 0;
  font-size: 3rem;
}
.vw_5_s3_line {
  border-bottom: 1px solid white;
  width: 8vw;
  margin: 0 auto;
}
.vw_5_inp_ctr {
  outline: none;
  border: 2px solid #a3bb33;
  display: flex;
  width: max-content;
  margin: 2rem auto;
  border-radius: 4px;
  align-items: center;
}
.vw_5_s3 input {
  margin-left: 0.4rem;
  font-size: 1.4rem;
  background: transparent;
  color: #a3bb33;
  outline: none;
  border: none;
}
.airplane {
  font-size: 2.2rem;
  background: #a3bb33;
  padding: 0.4rem;
  cursor: pointer;
  transition: all ease 0.2s;
}
.airplane:hover {
  opacity: 0.8;
}
.vw_5_s3_r1,
.vw_5_s3_r2 {
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.follow_icons {
  margin-right: 0.5rem;
  transition: all ease 0.4s;
  cursor: pointer;
}
.follow_icons:hover {
  opacity: 0.8;
}
.avilable_app_ctr img {
  height: 2.4rem;
  margin-right: 1rem;
  cursor: pointer;
}
.vw_5_s3_r2 img {
  height: 2.4rem;
  margin-right: 1rem;
}

.vw_5_s3_r3 {
  color: #a3bb33;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Font_I_thin;
  margin-bottom: 0;
  height: 2rem;
}
.vw_5_s3_r3 p {
  opacity: 0.2;
  cursor: pointer;
  transition: all ease 0.1s;
}
.vw_5_s3_r3 p:hover {
  opacity: 0.8;
}
.review_slider {
  padding: 0 !important;
}

/* Responsiveness vw_1 ctr_1*/
@media (max-height: 760px) and (max-width: 650px) {
  .img_txt {
    top: 5rem !important;
  }
}
@media (max-height: 695px) and (max-width: 650px) {
  .img_txt {
    top: 6rem !important;
  }
  .img_txt_1 {
    font-size: 3.2rem !important;
  }
  .img_txt_2 {
    font-size: 4rem !important;
  }
}
@media (max-height: 695px) and (max-width: 650px) {
  .img_txt {
    top: 6rem !important;
  }
  .img_txt_1 {
    font-size: 3rem !important;
  }
  .img_txt_2 {
    font-size: 3.6rem !important;
  }
}

@media (max-height: 386px) {
  .ctr1_line {
    border-bottom: 0px solid white;
    min-width: max-content;
    width: 22%;
  }
  .ctr1_line button {
    font-size: .2rem !important;
    padding: 0.4rem 0.4rem;
    background: red !important;
  }
}
@media (max-width: 1880px) {
  .vw_1 .img_txt {
    top: 13rem;
    width: 44%;
  }
  .vw_1 .img_txt_1 {
    font-size: 5.2rem;
  }
  .vw_1 .img_txt_2 {
    font-size: 6.2rem;
  }
  .ctr1_line {
    border-bottom: 0px solid white;
    min-width: max-content;
    width: 22%;
  }
  .join_btn_ctr1 {
    font-size: 1.6rem;
    padding: 0.4rem 0.4rem;
  }
}
@media (max-width: 1700px) {
  .vw_1 .img_txt {
    top: 12rem;
  }
  .vw_1 .img_txt_1 {
    font-size: 4.8rem;
  }
  .vw_1 .img_txt_2 {
    font-size: 6rem;
  }
  .ctr1_line {
    bottom: 6rem;
  }
}
@media (max-height: 850px) {
  .ctr1_line {
    bottom: 5rem;
  }
}
@media (max-height: 800px) {
  .ctr1_line {
    bottom: 4rem;
  }
}
@media (max-width: 1450px) {
  .join_btn_ctr1 {
    font-size: 1.2rem;
  }
  .join_btn_ctr1 img {
    height: 1.1rem;
    padding: 0.2rem;
    margin-right: 0.5rem;
  }
  .nav_logo {
    height: 4rem;
  }
  .navigations {
    width: 54%;
    right: 26%;
  }
  .vw_1 .img_txt {
    top: 12rem;
  }
  .vw_1 .img_txt_1 {
    font-size: 4.8rem;
  }
  .vw_1 .img_txt_2 {
    font-size: 6rem;
  }
}
@media (max-width: 1366px) {
  .vw_1 .img_txt {
    width: 50%;
  }
  .vw_1 .img_txt {
    top: 9rem;
  }
  .vw_1 .img_txt_1 {
    font-size: 4rem;
  }
  .vw_1 .img_txt_2 {
    font-size: 5rem;
  }
}
/* vw_1 ctr2 */
@media (max-height: 928px) {
  .nav2 button {
    font-size: 1rem;
  }
  .nav2 img {
    height: 1rem;
  }
  .trial_ctr {
    padding: 0.3rem 2rem;
    margin-top: 7rem;
    font-size: 1.4rem;
    font-weight: 600;
    box-shadow: 0 0 16px 12px rgba(83, 83, 83, 0.171);
  }
  .trial_inp {
    padding: 0.4rem;
    margin-bottom: 2rem;
  }
  .best_txt {
    margin: 0 auto;
    margin-top: 2rem;
  }
  .best_fitbuddy {
    font-family: Font_medium;
    font-size: 3rem;
  }
  .best_session {
    color: white;
    font-family: Font_medium;
    font-size: 3rem;
  }
  .best_arrow {
    font-size: 10rem;
  }
  .carousal2 {
    width: 78%;
    border-radius: 1.8rem;
  }
  .fixed_ctr button {
    font-size: 1.1rem;
  }
}

@media (max-height: 875px) {
  .nav2 {
    padding-top: 3rem;
  }
  .nav2 button {
    font-size: 0.9rem;
    border: 2px solid black;
  }
  .join_btn img {
    height: 0.8rem;
    border: 2px solid black;
  }
  .line_ctr {
    margin: 1.4rem 0 1rem 0;
  }
  .line_ctr p {
    font-size: 1rem;
    padding: 0 1rem;
  }
  .trial_ctr {
    padding: 0.8rem 1rem;
    margin-top: 8rem;
    font-size: 1.4rem;
  }
  .trial_ctr p {
    margin-bottom: 1.4rem;
    line-height: 0;
  }
  .trial_media {
    font-size: 1.5rem;
  }
  .trial_media_ctr {
    margin-bottom: 0.2rem;
  }
  .trial_inp {
    padding: 0.4rem;
    margin-bottom: 1rem;
  }

  .trial_login {
    padding: 0.4rem 1.2rem;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .best_txt {
    margin: 0 auto;
    margin-top: 4rem;
    line-height: 0;
  }
  .best_fitbuddy {
    font-size: 3rem;
  }
  .best_session {
    font-size: 2.8rem;
  }
  .best_arrow {
    font-size: 8rem !important;
  }
  .carousal2 {
    margin-top: 1rem;
    width: 78%;
    border-radius: 1.8rem;
  }
  .fixed_ctr button {
    font-size: 1.1rem;
  }
}

@media (max-height: 823px) {
  .nav2 {
    padding-top: 1rem;
  }
  .line_ctr {
    margin: 1.4rem 0 0rem 0;
  }
  .trial_ctr {
    padding: 0.8rem 1rem;
    margin-top: 5rem;
    font-size: 2rem;
  }

  .trial_login {
    padding: 0.4rem 1.2rem;
    margin-bottom: 1rem;
  }
  .best_txt {
    margin: 0 auto;
    margin-top: 4rem;
    line-height: 0;
  }
  .best_fitbuddy {
    font-size: 3rem;
  }
  .best_session {
    font-size: 2.8rem;
  }
  .best_arrow {
    font-size: 8rem !important;
  }
  .carousal2 {
    margin-top: 1rem;
    width: 78%;
    border-radius: 1.8rem;
  }
  .fixed_ctr button {
    font-size: 1.1rem;
  }
}

@media (max-height: 785px) {
  .best_txt {
    margin-top: 2rem;
  }
  .best_fitbuddy {
    font-size: 2.8rem;
  }
  .best_session {
    font-size: 2.4rem;
  }
  .best_arrow {
    font-size: 6rem !important;
  }
  .carousal2 {
    margin-top: 1rem;
    width: 78%;
    border-radius: 1.8rem;
  }
}

@media (max-height: 785px) {
  .trial_ctr {
    margin-top: 5rem;
    font-size: 1.8rem;
  }
  .best_txt {
    margin-top: 2rem;
  }
  .best_fitbuddy {
    font-size: 2.4rem;
  }
  .best_session {
    font-size: 2.2rem;
  }
  .carousal2 {
    margin-top: 0.2rem;
    width: 78%;
    border-radius: 1.8rem;
  }
}
@media (max-height: 730px) {
  .carousal2 video {
    height: 12rem;
  }
  .trial_inp {
    font-size: 1rem;
  }
  .trial_ctr {
    margin-top: 5rem;
    font-size: 1.5rem;
  }
  .trial_login {
    padding: 0.4rem 1.2rem;
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }
  .line_ctr {
    margin: 1rem 0;
  }
  .line_ctr p {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1.3rem;
    font-size: 1rem;
    background-color: white;
    height: 1rem;
    width: 6rem;
    padding: 0 0.8rem;
  }
  .best_fitbuddy {
    font-size: 2.4rem;
  }
  .best_session {
    font-size: 2.2rem;
  }
  .carousal2 {
    margin-top: 0.2rem;
    width: 78%;
    border-radius: 1.8rem;
  }
}
@media (max-height: 708px) {
  .trial_inp {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
  .trial_ctr {
    margin-top: 4.5rem;
    font-size: 1.4rem;
  }
  .trial_login {
    padding: 0.2rem 1rem;
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
  }
  .line_ctr {
    margin: 1rem 0;
  }
  .line_ctr p {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1.3rem;
    font-size: 1rem;
    background-color: white;
    height: 1rem;
    width: 6rem;
    padding: 0 0.8rem;
  }
  .best_fitbuddy {
    font-size: 2.2rem;
  }
  .best_session {
    font-size: 2rem;
  }
  .carousal2 {
    margin-top: 0;
  }
}
/* mobile version */
@media (max-width: 650px) {
  .main_ctr {
    overflow: hidden;
  }
  .vw_1 {
    display: flex;
    height: max-content;
    flex-direction: column;
  }
  .ctr1 {
    position: relative;
  }
  .ctr1,
  .ctr2 {
    width: 100%;
    /* position: absolute; */
    height: max-content;
  }
  .ctr2 {
    /* background-color: transparent; */
    /* display: none; */
    padding-bottom: 2rem;
  }
  .nav {
    display: none;
  }
  /* .nav p {
    font-family: Font_thin;
    font-size: 1rem;
  }
  .nav_logo {
    height: 3rem;
  }
  .navigations {
    width: 80%;
    right: 2%;
  } */
  .mobile_menu {
    display: inline;
    font-size: 2rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 9;
    cursor: pointer;
    transition: all ease 0.4s;
  }
  .mobile_menu:active {
    font-size: 1.8rem;
  }
  .p_menu_ctr {
    height: max-content;
    width: max-content;
    background-color: #a3bb34;
    border-radius: 4px;
    position: absolute;
    z-index: 8;
    right: 2rem;
    top: 4rem;
    user-select: none;
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    box-shadow: 2px 2px 8px;
  }
  .p_menu_ctr img {
    filter: brightness(0);
    user-select: none;
  }
  .p_menu_ctr p {
    all: unset;
    font-family: Font_medium;
    user-select: none;
    height: min-content;
    display: flex;
    align-items: center;
    padding: 00.4rem;
    cursor: pointer;
    border-radius: 4px;
  }
  .p_menu_ctr p:hover {
    background-color: rgba(0, 0, 0, 0.226);
  }
  .p_menu_ctr p:active {
    transform: translateY(2%);
  }
  .nav_logo_mobile {
    display: block;
    position: absolute;
    z-index: 9;
    height: 2.8rem;
    top: 1rem;
    left: 1rem;
    user-select: none;
    cursor: pointer;
  }
  .ctr1_line {
    padding-bottom: 2rem;
    border-bottom: 0px solid white;
    bottom: 4rem;
    width: max-content;
    margin-left: 1rem;
  }
  .ctr1_line img {
    height: 1rem;
    border: 2px solid #a3bb33;
    border-radius: 50%;
    padding: 0.3rem;
    margin-right: 0.4rem;
  }
  .ctr1_line button {
    font-size: 1rem;
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;
    border: 2px solid #a3bb33;
  }
  .img_txt {
    left: 1rem;
  }
  .carousel_image1 {
    background-image: url("./carousel_images/mobile/1.jpg");
  }
  .carousel_image2 {
    background-image: url("./carousel_images/mobile/2.jpg");
  }
  .carousel_image3 {
    background-image: url("./carousel_images/mobile/3.jpg");
  }
}

/* vw_2 responsiveness */
@media (max-width: 1366px) {
  .txt1 {
    font-size: 3rem;
    margin-left: 3rem;
    line-height: 0;
  }
  .line_nav button {
    font-size: 1.8rem;
  }
  .arr_right,
  .arr_left {
    bottom: -0.4rem !important;
    font-size: 5rem !important;
  }
  .vw_2 {
    height: 110vh;
  }
}
@media (max-width: 1100px) {
  .txt1 {
    font-size: 3rem;
    margin-left: 3rem;
  }
  .line_nav button {
    font-size: 1.8rem;
  }
  .arr_right,
  .arr_left {
    font-size: 5rem !important;
  }
}
@media (max-width: 650px) {
  .txt1 {
    font-size: 1.8rem;
    margin-left: 1rem;
  }
  .line_nav button {
    font-size: 1.4rem;
    border: 2px solid #a3bb33;
    margin-right: 1rem;
  }
  .arr_right,
  .arr_left {
    font-size: 5rem !important;
  }
  .vw_2 {
    min-height: max-content;
    height: 90vh;
  }
}
/* vw_3 */
@media (max-width: 1520px) {
  .vw_3 {
    height: 111vh;
  }
  .video_ctr video {
    height: 16rem !important;
  }
  .vw_3_pre_video,
  .vw_3_post_video {
    padding: 0.6rem;
    font-size: 1.3rem;
  }
  .vw_3_ctr_2 {
    display: flex;
    position: absolute;
    right: 4rem;
  }
  .vw_3_why {
    font-size: 3rem;
  }
  .v3_fitbuddy {
    font-size: 4.6rem;
  }
  .vw_3_txt_box {
    display: flex;
    line-height: 22px;
  }
  .vw_3_txt_box_line1 {
    font-size: 1.8rem;
  }
  .vw_3_txt_box_line2 {
    font-size: 1.2rem;
  }
  .vw_3_txt_box img {
    width: 5rem;
    height: 6rem;
    margin: 1.4rem 1rem;
  }
  .vw_3_txt {
    padding-top: 2rem;
  }
}

/* vw_3 mobile */

@media (max-width: 650px) {
  .vw_3 {
    display: flex;
    flex-direction: column-reverse;
    height: max-content;
    align-items: center;
  }
  .vw_3_ctr_2 {
    position: unset;
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  .vw_3_pre_video,
  .vw_3_post_video {
    padding: 0.4rem 0;
    width: 100%;
    font-size: 1.1rem;
  }
  .vw_3_video_frame {
    padding-top: 2rem;
    width: 88%;
    margin: 0 auto;
  }
  .vw_3_ctr_1 {
    position: unset;
  }
  .video_ctr,
  .video_ctr video {
    width: 100% !important;
  }
  .vw_3_ctr_2 {
    height: max-content;
  }
  .vw_3_ctr_1 {
    width: 100vw;
    height: max-content;
    margin-left: 0;
    justify-content: space-around;
  }
  .vw_3_txt {
    margin-left: 2rem;
  }
  .vw_3_why {
    font-size: 2.4rem;
  }
  .v3_fitbuddy {
    font-size: 3.4rem;
  }
  .vw_3_txt_box {
    width: 80vw;
  }
  .vw_3_txt_box img {
    width: 3.2rem;
    height: 4rem;
    margin: 0.8rem;
  }
  .vw_3_txt_box_line1 {
    font-size: 1.7rem;
  }
  .vw_3_txt_box_line2 {
    font-family: Font_smedium;
    font-size: 1rem;
  }
}

/* vw_4  */
@media (max-width: 1366px) {
  .mem_txt {
    top: -0.8rem;
    font-size: 4rem;
  }
  .mem_carousal {
    top: 12rem !important;
  }
  .membership_card {
    width: 16rem;
  }
  .plan_name {
    font-size: 1.5rem;
  }
  .mem_duration {
    font-size: 1rem;
  }
  .ssn_time_by_you {
    padding: 0.2rem 0.8rem;
    font-size: 1.05rem;
  }
  .plan_readbtn {
    padding: 0.2rem 0.8rem;
    font-size: 1.1rem;
    cursor: pointer;
  }
}

@media (max-height: 1050px) {
  .vw_4 {
    height: 110vh;
  }
}
@media (max-height: 950px) {
  .vw_4 {
    height: 115vh;
  }
}
@media (max-height: 900px) {
  .vw_4 {
    height: 120vh;
  }
}
@media (max-height: 860px) {
  .vw_4 {
    height: 128vh;
  }
}
@media (max-height: 810px) {
  .vw_4 {
    height: 135vh;
  }
}
@media (max-height: 760px) {
  .vw_4 {
    height: 138vh;
  }
}

@media (max-width: 650px) {
  .vw_4 {
    max-height: 100vh;
  }
  .membership_card img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    border-bottom: 1px solid #499e99;
  }
  .mem_txt {
    top: 0rem;
    font-size: 3rem;
  }
  .mem_carousal {
    top: 10rem !important;
  }
  .membership_card {
    margin: 0 2rem;
    width: 14rem;
    border-radius: 1rem;
    border-right: 2px solid #499e99;
    border-bottom: 2px solid #499e99;
  }
  .plan_name {
    font-size: 1.4rem;
  }
  .mem_duration {
    font-size: 1rem;
  }
  .ssn_time_by_you {
    padding: 0.1rem 0.4rem;
    font-size: 1rem;
  }
  .plan_readbtn {
    padding: 0.1rem 0.4rem;
    font-size: 1rem;
    cursor: pointer;
  }
}

/* vw_5 responsiveness */

@media (max-width: 1366px) {
  .vw_5_s2 p {
    font-family: Font_italic;
    font-size: 2.4rem;
    margin-right: 2rem;
  }
  .vw_5_s2 span {
    font-family: Font_I_Xthin;
    font-size: 2.5rem;
  }
  .vw_5_s2_line {
    border-bottom: 2px solid black;
    width: 8rem;
  }
  .vw_5_s2_txt {
    height: 2rem;
    margin: 1rem auto 0.4rem;
  }
  .client_logos {
    height: 4rem;
    margin: 0 2rem;
  }
  .vw_5_s3_join {
    font-size: 2rem;
  }

  .vw_5_s3 input {
    font-size: 1.2rem;
  }
  .airplane {
    font-size: 2rem;
  }
  .vw_5_s3_r1,
  .vw_5_s3_r2 {
    color: white;
    font-size: 1.2rem;
  }
  .follow_icons {
    margin-right: 0.8rem;
  }
  .avilable_app_ctr img {
    height: 2.4rem;
    margin-right: 1rem;
    cursor: pointer;
  }
  .vw_5_s3_r2 img {
    height: 2.4rem;
    margin-right: 1rem;
  }

  .vw_5_s3_r3 {
    font-size: 1.2rem;
  }
  .vw_5_s3_r3 p {
    opacity: 0.2;
  }
}
/* vw_5 mobile */

@media (max-width: 650px) {
  .vw_5_call{
    display: none !important;
  }
  .HD_media_icons{
    display: none !important;
  }
  .phone_media_icons{
    display: block !important;
  }
  .review_txt {
    font-size: 2rem;
    top: -1rem;
  }
  .stars {
    color: #a3bb34;
    margin: 2rem;
    font-size: 1.2rem;
  }
  .vw_5_review_txt {
    font-size: 1rem;
  }
  .vw_5_s1_profile {
    height: 4rem;
    width: 4rem;
    border: 2px solid #a3bb34;
  }
  .vw_5_s1_line {
    border-bottom: 2px solid #a3bb34;
    width: 16%;
    margin: 1rem auto;
  }

  .vw_5_s2 p {
    font-size: 2rem;
    margin-right: 1rem;
  }
  .vw_5_s2 span {
    font-size: 2rem;
  }
  .vw_5_s2_txt {
    margin: 1em auto;
  }
  .vw_5_s2_line {
    border-bottom: 2px solid black;
  }

  .client_logos_ctr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    height: max-content;
    padding-bottom: 0.8rem;
  }
  .client_logos {
    height: 4rem;
    margin: 0 2rem;
  }
  .vw_5_s3 {
    height: max-content;
    padding: 0;
  }
  .vw_5_s3_join {
    color: #a3bb33;
    font-family: Font_I_thin;
    margin: 0;
    font-size: 1.5rem;
  }
  .vw_5_inp_ctr {
    margin: 1rem auto;
  }
  .vw_5_s3 input {
    font-size: 1rem;
  }
  .airplane {
    font-size: 1.2rem;
    background: #a3bb33;
  }
  .vw_5_s3_r1,
  .vw_5_s3_r2 {
    font-size: 1rem;
  }
  .follow_icons {
    margin-right: 0.5rem;
    transition: all ease 0.4s;
    font-size: 1rem;
    cursor: pointer;
  }
  .avilable_app_ctr img {
    height: 1rem;
  }
  .vw_5_s3_r2 img {
    height: 1rem;
    margin-right: 1rem;
    display: none;
  }

  .vw_5_s3_r3 {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
  }
  .review_slider {
    padding: 0 !important;
  }
  .follow_media_icons {
    margin-left: 20%;
  }
}
