/* previously height and width were 38 and 40*/
.ctr {
  position: relative;
  height: 38rem; 
  width: 40rem;
  background-color: white;
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.ctr:hover {
  transform: scale(101%);
}
.backgroundImg {
  border-radius: 2rem;
  position: absolute;
  opacity: 0.3;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.ctr1 {
  font-family: Font_thin;
  width: 38%;
  margin-left: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: inherit;
  transition: all ease 0.8s;
}
.ctr1 div {
  width: 100%;
  text-align: left;
}
.ctr1 button {
  border-radius: 2rem;
  padding: 0.4rem 2rem;
  border: 2px solid black;
  font-size: 1.5rem;
  background-color: transparent;
  cursor: pointer;
  z-index: 9;
  position: absolute;
}
.ctr1 button:hover {
  transform: translateY(2px);
}
.ctr2 {
  position: absolute;
  width: 50% !important;
  top: 0;
  right: 1rem;
  height: 35rem;
  bottom: 0;
  margin: auto 0;
  border: 4px solid black;
  border-radius: 2rem;
  text-align: left;
}

.exercise_label {
  font-family: Font_Bold;
  font-size: 2.2rem;
}
.desc {
  font-family: Font_medium;
  font-size: 1.2rem;
}
.round_trainer {
  height: 5rem;
  width: 5rem !important;
  border-radius: 50%;
  border: 2px solid black;
}
.trainer_info p {
  font-family: Font_medium;
}
/* responsive design */
@media (max-width: 1366px) {
  .ctr {
    height: 32rem;
    width: 30rem;
    background-color: white;
  }
  .ctr1 {
    margin-left: 1rem;
  }
  .label {
    line-height: 0;
  }
  .ctr2 {
    width: 50% !important;
    top: 0;
    right: 1rem;
    height: 28rem;
  }
  .ctr1 button {
    padding: 0.3rem 1.7rem;
    font-size: 1.2rem;
  }
  .round_trainer {
    height: 4rem;
    width: 4rem !important;
  }
}
@media (max-width: 1100px) {
  .ctr {
    height: 32rem;
    width: 30rem;
    background-color: white;
  }
  .ctr1 {
    margin-left: 1rem;
  }
  .label {
    line-height: 0;
  }
  .ctr2 {
    width: 50% !important;
    top: 0;
    right: 1rem;
    height: 28rem;
  }
  .ctr1 button {
    padding: 0.3rem 1.7rem;
    font-size: 1.2rem;
  }
  .round_trainer {
    height: 4rem;
    width: 4rem !important;
  }
}
@media (max-height: 880px) {
  .ctr {
    height: 32rem;
    width: 30rem;
    background-color: white;
  }
  .ctr1 {
    margin-left: 1rem;
  }
  .label {
    line-height: 0;
  }
  .ctr2 {
    width: 50% !important;
    top: 0;
    right: 1rem;
    height: 28rem;
  }
  .ctr1 button {
    padding: 0.3rem 1.7rem;
    font-size: 1.2rem;
  }
  .round_trainer {
    height: 4rem;
    width: 4rem !important;
  }
}
@media (max-width: 650px) {
  .ctr {
    height: 28rem;
    width: 15rem;
    background-color: white;
  }
  .ctr1 {
    width: 100%;
    margin: 0;
    padding: 0.4rem;
  }
  .label {
    line-height: 0;
  }
  .ctr2 {
    display: none;
  }
  .ctr1 button {
    padding: 0.3rem 1.7rem;
    font-size: 1.2rem;
  }
  .round_trainer {
    height: 4rem;
    width: 4rem !important;
  }
  .trainer_info{
    display: flex;
    align-items: center;
  }
  .trainer_info p {
    font-size: 1.1rem;
  }
}

@media(max-width: 1366px) {
  
}